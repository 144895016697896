import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { EnvService } from 'src/app/env.service';
import { CustomValidators } from '../validator/must-match.validator';
import * as jwt_decode from 'jwt-decode';
import { SharedService } from 'src/app/core/services/shared.service';
import { KeycloakService } from 'keycloak-angular';
import { VendorService } from 'src/app/core/services/vendor.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {
    // @Input() ResetPasswordForm :ResetPasswordFormModule;
    token: any;
    data = { // Required
      "type": "reset password component", //Required. Impression type (list, detail, view, edit, workflow, search)
      "subtype": "", //Optional. Additional subtype. "Paginate", "Scroll"
      "pageid": "resetid", //Required.  Unique page id
      "itype": "", // type of interaction - SWIPE, SCRUB (fast forward using page thumbnails) or AUTO
      "stageto": "" // game level, stage of page id to which the navigation was done
    };
    // private _telemetryServices : TelemetryServices
    // private service: UserService
    // private keycloakService: KeycloakService
    constructor(private snackBar: MatSnackBar, private fb: UntypedFormBuilder, private router: Router, private env: EnvService,private sharedService:SharedService, private  keycloakService: KeycloakService, private vendorService: VendorService ) {
      this.changeTheme();
     }
  
    isPwdToggle: boolean = true;
    isCPwdToggle: boolean = true;
    isOPwdToggle: boolean = true;
    loggedUserDetails: any;
    ngOnInit() {
  
  
    }
  
    togglePasswordOn() {
      this.isPwdToggle = false;
    }
  
    togglePasswordOff() {
      this.isPwdToggle = true;
    }
  
    toggleCPasswordOn() {
      this.isCPwdToggle = false;
    }
  
    toggleCPasswordOff() {
      this.isCPwdToggle = true;
    }
  
    ResetPasswordForm: UntypedFormGroup = this.fb.group({
      oldPassword:["", [Validators.required,Validators.minLength(8)]],
      passwordX: ["", Validators.compose([Validators.required, Validators.maxLength(50),
      CustomValidators.patternValidator(/\d/, { hasNumber: true }),
      CustomValidators.patternValidator(/[A-Z]/, { hasCapitalCase: true }),
      CustomValidators.patternValidator(/[a-z]/, { hasSmallCase: true }),
      // CustomValidators.patternValidator(/[@#$%^&*()]/, { hasSpecialCharacters: true }),
      CustomValidators.patternValidator(/[$@$!%*#?&]/, { hasSpecialCharacters: true }),
      Validators.minLength(8)
      ])],
      cpasswordX: ["", Validators.compose([Validators.required])],
    }, { validator: CustomValidators.passwordMatchValidator });
  
    refresh() {
      // this.ResetPasswordForm.reset();
      this.router.navigate(['jobs/privacy-consent']);
    }
  
  
    submit() {
      if (this.ResetPasswordForm.valid) {
       
        let accessCode = localStorage.getItem("access");
        // var jwt = jwt_decode(accessCode);
        let data = this.ResetPasswordForm.value;
        let pwd = data.passwordX;
        let obj={
            "userName":localStorage.getItem("email"),  
            "password": pwd,   
            "oldpassword":data.oldPassword,       
            "clientid":"UGFydG5lckh1Yg=="
            }

        // let userObj = {    
        //   "password": JSON.parse(JSON.stringify(pwd))
        // };
        // jwt.sub
        this.sharedService.resetPassword(JSON.parse(JSON.stringify(obj))).subscribe(res => {
          // res = JSON.parse(res);
          if (res?.details == "Password is updated successfully")
           {
            let info = {
              msg: "Password is updated successfully",
              isSuccess: true
            }
            this.vendorService.openSnackBar(info,'success-snack');
            this.logout();
            // this.snackBar.open("Password is updated successfully", "", { duration: 5000, panelClass: ['privacy_success'] })
            //logs out all sessions
            //remove string for jwt.sub
            // this.sharedService.logoutSession('jwt.sub').subscribe(resp => {
            //   resp= JSON.parse(resp);
            //   if(resp.response === "User Logout Successful"){
            //     var countrycode = localStorage.getItem('countrycode');
            //     var companyhiringtype = localStorage.getItem('companyhiringtype');
            //     const parsedUrl = new URL(document.location.href);
            //     const baseUrl = parsedUrl.origin;
            //     setTimeout(() => {
            //       localStorage.clear();
            //       if (companyhiringtype && countrycode) {
            //         this.keycloakService.logout(baseUrl + 'app-wall/app-generichome/generictemplate/');
            //       } else {
            //         this.keycloakService.logout(baseUrl + 'app-wall/app-generichome/generictemplate/');
            //       }
            //       }, 6000);
                
            //     // // setTimeout(() => {
            //     // //   this.router.navigate(['/joblist']).then(() =>{
            //     // //     window.location.reload();
            //     // //   })
            //     // // }, 6000);
            //   }
            //   else{
            //     console.log("not successfully logged out from all sessions");
                
            //   }
         
              
            // });
            this.sharedService.logoutSession();
  
            
          }
          else if (res.details  == "Password Update Unsuccessful : Password history / complexity doesn't match") 
          {
            let info = {
              msg: "Please use unidentical password to your last three passwords.",
              isSuccess: false
            }
            this.vendorService.openSnackBar(info,'success-snack');
            // this.snackBar.open("Please use unidentical password to your last three passwords.", "", { duration: 5000, panelClass: ['privacy_error'] })
            
          }
          else if (res.details == "Password Update Unsuccessful : Internal Server Error Occurred")
           {
            let info = {
              msg: "Password Update Unsuccessful due to Internal Server Error Occurred.",
              isSuccess: false
            }
            this.vendorService.openSnackBar(info,'success-snack');
            // this.snackBar.open("Password reset cannot be done as you are using social sign in.", "", { duration: 5000, panelClass: ['privacy_error'] })
          }
          else if (res.details === "Password Update Unsuccessful : User not exists") 
          {
            let info = {
              msg: "User doesn't exist",
              isSuccess: false
            }
            this.vendorService.openSnackBar(info,'success-snack');
            // this.snackBar.open("User doesn't exist", "", { duration: 5000, panelClass: ['privacy_error'] })
          }
          this.ResetPasswordForm.reset();
        
  
        }, error => {
          let info = {
            msg: error?.error?.message,
            isSuccess: false
          }
          this.vendorService.openSnackBar(info,'success-snack');
          // this.snackBar.open("Please use unidentical password to your last three passwords.", "", { duration: 5000, panelClass: ['privacy_error'] })
          console.error("error", error);
        })
      } else {
        this.ResetPasswordForm.markAllAsTouched();
      }
  
    }
    ngOnDestroy() {
      this.data.subtype = 'reset password component';
      this.data.itype = 'AUTO';
      this.data.stageto = 'reset password component to change password';
      if (this.data) {
        // this._telemetryServices.telemetryImpression(this.data);
      }
     
    }
  
    changeTheme()
    {
      let envBlobImage = this.env.bloburl+'/Images/bg/pyramid.png';
      document.documentElement.style.setProperty('--dynamic-url', "url("+envBlobImage+") 100%");
    }

    onCancel(){
      this.router.navigate(['/app-wall']);
    }

    logout() {
      this.sharedService.logoutSession();
      localStorage.removeItem("companyHiringTypeId");
      // let url = encodeURI(location.origin);
      localStorage.removeItem('selectedRole');
      // localStorage.removeItem("role")
      // localStorage.removeItem('userTabLocation');
  
      // localStorage.removeItem("navFlag");
      // window.location.href = this.keyClockUrl + '/realms/infosys/protocol/openid-connect/logout?redirect_uri=' + url;
      ///////
      // let initOptions = {
      //   url: 'https://identitypoc.infosysapps.com/auth', realm: 'infosys', clientId: 'infyclient'
      // }
      // let keycloak = Keycloak(initOptions);
      // keycloak.logout({redirectUri: "/"});
      console.log('localstorage1', localStorage);
      localStorage.removeItem('email');
      // localStorage.removeItem('kc-callback-6024f0d1-7bdf-4f94-807a-935722436563');
      localStorage.removeItem('lastName');
      // localStorage.removeItem('access-token');
      localStorage.removeItem('firstName');
      this.router.navigate(['/']);
      console.log('localstorage2', localStorage)
    }
  
  }
  
  export interface ResetPasswordFormModule {
    password: string,
    cpassword: string,
  }