import { Component, OnInit, Input } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { CustomValidators } from '../validator/must-match.validator';
import { Router } from '@angular/router';
import { EnvService } from 'src/app/env.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SharedService } from 'src/app/core/services/shared.service';
@Component({
  selector: 'forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
  // @Input() ForgotPasswordForm :ForgotPasswordFormModule;
 
  constructor(private router: Router, private snackBar: MatSnackBar, private fb: UntypedFormBuilder, private sharedService: SharedService ) { 
    // this.changeTheme(); 
  }

  isPwdToggle: boolean = true;
  isCPwdToggle: boolean = true;
  lastClickTime:number = 0;
  clickedTimes:number = 0;
  prevId = '';

  ngOnInit() {
      console.log('logs added for message');

  }

  togglePasswordOn() {
    this.isPwdToggle = false;
  }

  togglePasswordOff() {
    this.isPwdToggle = true;
  }

  toggleCPasswordOn() {
    this.isCPwdToggle = false;
  }

  toggleCPasswordOff() {
    this.isCPwdToggle = true;
  }

  ForgotPasswordForm: UntypedFormGroup = this.fb.group({
    emailX: ["", [Validators.required, Validators.email]]
  });

  refresh() {
    this.router.navigate(['app-wall']);
  }

  submit() {
    if (this.ForgotPasswordForm.valid) {
      let data = this.ForgotPasswordForm.value;

      this.sharedService.forgotPassword(data).subscribe(res => {
        if (res.details == 'Password Reset Successful   : Password Reset Mail is triggered'){
          let info = {
            msg: "Please check your email to reset password.",
            isSuccess: true
          }
          this.sharedService.openSnackBarNew(info, "success-snack",5000);
          // this.snackBar.open("Please check your email to reset password.", "", { duration: 5000, panelClass: ['privacy_success'] })
          setTimeout(function () {
            a.router.navigate(['app-wall/']);
          }, 6000)
        }
        else if (res.details === "Password Reset Unsuccessful : User is using social sign in") 
        {
          let info = {
            msg: "Password reset cannot be done as you are using social sign in.",
            isSuccess: false
          }
          this.sharedService.openSnackBarNew(info,'danger-snack',5000);
          // this.snackBar.open("Password reset cannot be done as you are using social sign in.", "", { duration: 5000, panelClass: ['privacy_error'] })
        } 
        else if (res.details === "Password Reset Unsuccessful : User not exists") 
        {
          let info = {
            msg: "Password Reset Unsuccessful since this email Id is not registered with us.",
            isSuccess: false
          }
          this.sharedService.openSnackBarNew(info,'danger-snack',5000);
          // this.snackBar.open("You will get the password reset mail in your inbox if this email is registered with us.", "", { duration: 5000, panelClass: ['privacy_error'] })
        } 
        else if (res.details === "Password Reset Unsuccessful : Internal Server Error Occurred") {
          let info = {
            msg: "Password reset cannot be done due to some technical issue.",
            isSuccess: false
          }
          this.sharedService.openSnackBarNew(info,'danger-snack',5000);
          // this.snackBar.open("Password reset cannot be due to some technical issue.", "", { duration: 5000, panelClass: ['privacy_error'] })
        }
        else if (res.details === "Password Reset Unsuccessful : The feature is available after 15mins") {
          let info = {
            msg: res.details,
            isSuccess: false
          };
          this.sharedService.openSnackBarNew(info, 'danger-snack', 5000);
        }
        // if(res == 'Password Reset')
        // {
        //   this.snackBar.open("Please check your email to reset password.", "", { duration: 5000, panelClass: ['privacy_success'] })

          this.ForgotPasswordForm.reset();
          let a = this;
      
      

      },
        // err => {
        //   let error = JSON.parse(err.error);
        //   if(error.message == "Requested user not found")
        //   {
        //     this.snackBar.open("User is not registered", "", { duration: 7000, panelClass: ['privacy_error'] })
        //     this.ForgotPasswordForm.reset();
        //   }
        // }
      )
    } else {
      this.ForgotPasswordForm.markAllAsTouched();
    }
  }
  handleButtonClick(){
    this.clickedTimes++;
    // const now = Date.now();
    
    // const clickInterval = now - this.lastClickTime;
    if(this.clickedTimes <= 5  || this.prevId != this.ForgotPasswordForm.value.emailX){
      this.submit();
      this.prevId =  this.ForgotPasswordForm.value.emailX;
      // this.lastClickTime = now;
      return;
    }
    else{
      console.log('Rate limit exceeded');
    }
    // if(clickInterval > 10000 && this.clickedTimes > 1 && clickInterval != now){
    //   console.log('Rate limit exceeded');
    //   this.submit();
    //   this.lastClickTime = now;
    //   return;
    // }
    console.log('Button Clicked');
  }
  // changeTheme()
  // {
  //   let envBlobImage = this.env.bloburl+'/Images/bg/pyramid.png';
  //   document.documentElement.style.setProperty('--dynamic-url', "url("+envBlobImage+") left top");
  // }
  onCancel(){
    this.router.navigate(['/app-wall']);
  }

}

export interface ForgotPasswordFormModule {
  password: string,
  cpassword: string,
}