<div class="d-flex justify-content-between ">
    <h2 mat-dialog-title>Payment</h2>
    <i mat-dialog-close class="crossBtn icon x-24 close-icon alignSelf" (click)="closeDialog()"></i>
</div>
<mat-dialog-content>
    <div class="DSA_msgCtr DSA_wb_Info marB16" style="width: auto">
        <span class="marR8"><img alt="Info" src="assets/images/vendor_icons/icon-info.png" class="info-grey"/></span>
        <b class="marB4">
            Please log into the Ariba Portal for any payment-related matters.<a class="marL4"
                href="https://supplier.ariba.com/" target="_blank">Click here</a></b>
    </div>
</mat-dialog-content>