import { ChangeDetectorRef, Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { Fields } from 'src/app/shared/models/candidateprofile-genmodel';
import { UntypedFormGroup } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { FileUploadModel } from 'src/app/shared/models/filemodel';
import { FileService } from './file.service';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { Subject } from 'rxjs';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export interface AttachData {
  attachmentList: any;
}

@Component({
  selector: 'app-attachmentDragDrop',
  templateUrl: './attachment-DragDrop.component.html',
  styleUrls: ['./attachment-DragDrop.component.scss'],
  animations: [
    trigger('fadeInOut', [
      state('in', style({ opacity: 100 })),
      transition('* => void', [
        animate(3000, style({ opacity: 0 }))
      ])
    ])
  ]
})
export class AttachmentDragDropComponent implements OnInit {

  field: Fields;
  group: UntypedFormGroup;
  requiredBool:boolean;
  multiple: boolean = true;
  multiSelectFlag: boolean = false;
  fileSizeError: any;
  fileTotalSize: any;
  uploadResponse = { status: '', message: '', filePath: '' };
  private files: Array<FileUploadModel> = [];
  fileMerge = [];
  fileMultiMerge = [];
  filenameProgressbar: string;
  filename = [];
  fileArray = [];
  private attachData: Array<AttachData> = [];
  allowedFileTypes: string = ""
  uploadType: string = 'single';
  fileDeleteSub = new Subject();
  key: number;
  lastindex: number;
  @Output() fileOutput = new EventEmitter<object>();
  @ViewChild('file', { static: false }) fileHtml: ElementRef<HTMLInputElement>;
  
  constructor(private cd : ChangeDetectorRef) {}
  // ngOnInit() {    
  //   this.field.options.forEach(element => {
  //     if(element.isSelected === true){  
  //       console.log(this.field.name);
  //       this.group.get(this.field.name).setValue(element.value);
  //     }
  //   });
  // }
  ngOnInit() {
    // console.log(this.group);
    console.log(this.field);
    
    this.allowedFileTypes=this.field?.hintMessage?this.field.hintMessage:"Doc, .Docx, Xls, Xlsx, Pdf, Jpeg, Tiff, .Txt, Rtf, Gif, .Png, .Csv, .Zip, .Msg, Jpg, .Tif. Preferred File Size Is Within 2 MB";
  }

    /**For File Select functionality**/
    onFileSelected(event) {
      let files = event.dataTransfer ? event.dataTransfer.files : event.target.files;
      this.fileUploading(files);
      this.cd.detectChanges();
    }
  
    /**For Drag and Drop functionality**/
    uploadFile(event) {
      let files = event;
      this.fileUploading(files);
    }
  
  
    fileUploading(files) {
      console.log(files,"uploaded",this.filename);
      
      if (this.uploadType == "single") {
        this.fileMerge = [];
        this.fileArray.splice(0, this.fileArray.length);
        this.filename.splice(0, this.filename.length);
      }
      this.key = 1;
      let errorFile = false;
      for (let file of files) {
        if (!this.validate(file)) {
          errorFile = true;
        }
      }
  
      if (!errorFile) {
        for (let file of files) {
          if (this.filename.indexOf(file.name) === -1) {
            this.fileMerge = []; //not used still
            this.fileArray.push(file); //pushing file
            this.filename.push(file.name); //pushing file name
          } else {
            const fileArrValues = [];
            this.fileArray.forEach(fileArr => {
              if (file.name != fileArr['name']) {
                fileArrValues.push(fileArr);
                // console.log(fileArr.file)
              }
            });
            //Override the last same name file
            delete this.fileArray;
            this.fileArray = fileArrValues;
            this.fileArray.push(file);
  
          }
        }
        this.onFileSelectedChunks(this.fileArray);  
      }
    }
  
  
    validate(file: File) {
      this.fileSizeError = "";
  
      const fileType = this.getFileExtension(file.name);

      let nameValidation = this.field.validations.find(val =>{
        return val['name'].toLowerCase().trim() == "pattern";
      })
  
      // if(file.size > 5242880 && (fileType == 'xlsx' || fileType == 'xls' ))
      // {
      //       this.fileSizeError = 'Please upload maximum 5MB size only.';
      //       return false;
      // } else if(file.size > 2097152 && (fileType == 'docx' || fileType == 'pdf' ))
      // {
      //       this.fileSizeError = 'Please upload maximum 2MB size only.';
      //       return false;
      // }else if (fileType != 'docx' && fileType != 'pdf' && fileType != 'xlsx' && fileType != 'xls' && fileType != 'png' && fileType != 'jpg' && fileType != 'jpeg'){
      //       this.fileSizeError = 'Please upload the correct format docx|pdf|xls.';
      //       return false;
      // }
      // return true;
  
      if (file.size > 2097152 && (fileType === 'pdf' || fileType === 'docx' || fileType === 'doc')) {
        this.fileSizeError = 'Please upload maximum 2MB size only.';
        return false;
      }
      else if (!(fileType.toLowerCase() === 'pdf' || fileType.toLowerCase() === 'docx' || fileType.toLowerCase() === 'doc')) {
        this.fileSizeError = 'Please upload the correct format doc|docx|pdf';
        return false;
      }
      else if(!file.name.match(nameValidation.value)){
        this.fileSizeError = nameValidation.message;
        return false;
      }
      else {
        return true;
      }
  
  
    }
  
    // closeDialog() {
    //   this.dialogRef.close();
    // }
  
    getFileExtension(filename) {
      return filename.split('.').pop();
    }
  
    removeParent(filename) {
      let FileValuesDelete = [];
      this.filename.forEach(file => {
        if (file != filename) {
          FileValuesDelete.push(file);
        }
      });
      delete this.filename;
      this.filename = FileValuesDelete;
  
      let FileArrayValuesDelete = [];
      this.fileArray.forEach(file => {
        if (file.name != filename) {
          FileArrayValuesDelete.push(file);
        }
      });
      delete this.fileArray;
      this.fileArray = FileArrayValuesDelete;
      // console.log( this.fileArray) 
      this.fileSizeError = '';
      this.fileHtml.nativeElement.value = '';
      this.group.get(this.field.name).setValue(null);
      // console.log("file uppp",this.filename);
      
    }

    
//Multi Chunks
onFileSelectedChunks(filesArray) {

  let files           = filesArray;
  this.filename       = [];
  this.fileMerge      = [];
  let lastChunksize   = 0;           
  let chunkSize       = 524288; //maximum 512 = 524,288, 256 = 262,144 B
  this.lastindex      = files.length; 
  this.key            = 1;

    for(let file of files)
    {  
      this.filename.push(file.name); 
      if(this.uploadType=='single' && this.filename.length>0){   
        this.fileMerge.splice(0,this.fileMerge.length);
      }
        this.readFile(file, lastChunksize, this.myCallback.bind(this),chunkSize);
    } 
    const fileMerge   = this.fileMerge;
    // const deletedFile = this.deletedFile;
    const nameOfTheFile = this.filename.join();
    // this.fileOutput.emit({fileMerge,nameOfTheFile})
    this.group.get(this.field.name).setValue({fileMerge})
    console.log(this.field.value,"fileuploaded");
    
  }

  // uploadSingleFile(file,lastChunksize,chunkSize){
  //   this.replaceFile(this.filename[0].fileID);
  //     this.fileDeleteSub.subscribe(() =>{             
  //       this.filename = [];
  //       //this.attachData = [];         
  //       this.readFile(file, lastChunksize, this.myCallback.bind(this),chunkSize);
  //     })
  // }

//  console.log('fileArray',this.fileArray)
myCallback(file, lastChunksize, result, chunkSize) {
  lastChunksize = lastChunksize + chunkSize;      
  if(result) {       
    this.readFile(file, lastChunksize, this.myCallback.bind(this), chunkSize);
  } else {
    ///end recursion
  }
 }


 readFile(file,lastChunksize: number, callback, chunkSize) {
  var fileBlob = file.slice(lastChunksize,lastChunksize+chunkSize); 
  if(fileBlob.size !=0) {
    let fileReader = new FileReader();
    fileReader.onloadend= (result)=>{
        this.fileMerge.push(file);      
        return callback(file,lastChunksize,fileReader.result, chunkSize)
    }
    fileReader.readAsText(fileBlob);    
  }else {   
    return callback(file,lastChunksize,false);
  }
}

// replaceFile(fileID){  
//   let observable =   this.fileService.deleteFile(fileID).subscribe(
//       (res) => {       
//           let FileValuesDelete = [];
//           this.filename.forEach(file => {            
//               if(file.fileID != fileID)
//               {
//                 FileValuesDelete.push(file);
//               }
//           });  
//           delete this.filename;
//           this.filename = FileValuesDelete;      
//           //this.fileSizeError = '';
//           this.fileService.setFileUploadEvent(this.filename);
//           let observ = this.fileDeleteSub.observers[this.fileDeleteSub.observers.length-1];
//           this.fileDeleteSub.observers.splice(0,this.fileDeleteSub.observers.length);
//           this.fileDeleteSub.observers.push(observ);
//           this.fileDeleteSub.next();
//       },
//       (err) => {        
//        // this.error = err
//       }
//     );   
// }
// removeFileName(){
//   this.fileNameArray.splice(0,this.fileNameArray.length);
// }

}
