<div *ngIf="data.isSuccess === true" class="icon white alert-success success-big-icon left16 snackBar" style="z-index:999 !important;"
  name="Success Message"> 
  <span class="msgClass">
    {{data.msg}}
  </span>
  <!-- <a  href="javascript:void(0);" class="marL16" style="color: #fff;"><u>Undo</u></a> -->
</div>

<div *ngIf="data.isSuccess === false" class="icon white alert alert-danger error-big-icon left16 snackBar"
  name="Failure Message">   <span class="msgClass">
    {{data.msg}}
  </span>
  <!-- <a  href="javascript:void(0);" class="marL16" style="color: #fff;"><u>Undo</u></a> -->
</div>

<div *ngIf="data.isSuccess === 'created'" class="icon white alert alert-danger error-big-icon left16 snackBar"
  name="Success Message">   <span class="msgClass">
    {{data.msg}}
  </span>

</div>

<div *ngIf="data.isSuccess === 'error'" class="icon white alert alert-danger error-big-icon left16 snackBar"
  name="Failure Message">   <span class="msgClass">
    {{data.msg}}
  </span>

</div>