<!-- <div id="checkbox-div" class="demo-full-width margin-top" [formGroup]="group" class="custom-form-field">
    <mat-checkbox class="example-margin" id={{field.name}} [formControlName]="field.name"><span style="font-weight: bold;">
        {{field.displayName}}
    </span></mat-checkbox>
</div> -->
<div class="container-fluid" id="attachmentDialog-container">
    <div class="row" id="attachmentDialog-container-row">
        <!-- <div id="attachmentDialog-container-div" class="col-11 nopadLR GreyDarkest h3Header">
            ADD ATTACHMENT
        </div> -->

    <div id="attachmentDialog-container-div3" appDrag (onFileDropped)="uploadFile($event)"
        class="row marTB24 dragDropBox">
        <div id="attachmentDialog-container-div4" style="text-align: center; margin: 0 auto; display: flex;"
            class="row">
            <div class="col-12 inB" id="attachmentDialog-container-div-5">
                <img id="attachmentDialog-container-img2" src="assets/images/icons/default_icons/icon-upload.png"
                    alt="upload" />

                <input id="attachmentDialog-container-input"
                    [attr.multiple]="uploadType=='multipleSelect' ? 'multiple':null" hidden accept="allowedFileTypes"
                    type="file" (change)="onFileSelected($event)" #file />
                <span id="attachmentDialog-container-span" class="h4Header GreyDarkest">Drag and Drop or </span>
                <span id="attachmentDialog-container-span2" class="clearFix  h4Header" style="cursor: pointer;"
                    (click)="file.click()"> 
                    <span class="browseCss">Browse file(s)</span> to Upload</span>
                <br id="attachmentDialog-container-br" />
            </div>
            <div id="attachmentDialog-container-div6" class="col-12 GreyLight mainBodyTxt4 nopadT inB relative" style="line-height: 30px;
            margin-top: 10px;color: #555555;">
                Allowed file types: {{allowedFileTypes}}
            </div>
        </div>
        <label id="attachmentDialog-container-label" class="file_error">{{fileSizeError}}</label>


    </div>
    <div class="row" id="attachmentDialog-container-div7">

        <mat-chip-listbox id="attachmentDialog-container-matChipList">
            <mat-chip-option id="attachmentDialog-container-matCHIP" class="DSA_wb_chipStyle-Big marR16 active"
                 *ngFor="let file of filename">
                <a id="attachmentDialog-container-a2" href="javascript:void();"
                     class="icon x-16 icon-close-mini chipIconRight" (click)="removeParent(file)"  ></a>
                 <b style="font-size: larger;">{{file}}</b>
             
            </mat-chip-option>
        </mat-chip-listbox>
    </div>
</div>